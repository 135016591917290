import './App.css'; // Import your CSS file here

import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import ShareGoodnessABI from "./contracts/ShareGoodness.json";
import MyTokenABI from "./contracts/ShareGoodnessToken.json";

const contractAddress = "0x2403a84A216c34958b3C8E370c268A7D652D3F5A"; // Update with your ShareGoodness contract address
const tokenAddress = "0x670427926a390174Ee8ded6D4489725e426FEac5"; // Update with your token address

function App() {
    const [connected, setConnected] = useState(false);
    const [contract, setContract] = useState(null);
    const [tokenContract, setTokenContract] = useState(null);
    const [donationAmount, setDonationAmount] = useState("");
    const [donorName, setDonorName] = useState("");
    const [donorMessage, setDonorMessage] = useState("");
    const [donations, setDonations] = useState([]);
    const [totalDonationsAmount, setTotalDonationsAmount] = useState(0);

    useEffect(() => {
        connectWallet();
    }, []);

    const connectWallet = async () => {
        if (window.ethereum) {
            try {
                await window.ethereum.request({ method: "eth_requestAccounts" });
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const contract = new ethers.Contract(contractAddress, ShareGoodnessABI.abi, signer);
                const tokenContract = new ethers.Contract(tokenAddress, MyTokenABI.abi, signer);
                setContract(contract);
                setTokenContract(tokenContract);
                setConnected(true);

                // Fetch donations history
                const donationsCount = await contract.getDonationsCount();
                const donations = [];
                let totalAmount = ethers.BigNumber.from(0); // Initialize total amount as BigNumber
                for (let i = 0; i < donationsCount; i++) {
                    const donation = await contract.donations(i);
                    donations.push(donation);
                    totalAmount = totalAmount.add(donation.amount); // Use BigNumber arithmetic
                }
                setDonations(donations.reverse()); // Reverse the array to show latest donations first
                setTotalDonationsAmount(totalAmount.toString()); // Convert BigNumber to string for display
            } catch (error) {
                console.error("Error connecting wallet:", error);
            }
        } else {
            alert("Please install MetaMask extension.");
        }
    };

    const makeDonation = async () => {
      if (!connected) {
          console.error("Wallet not connected");
          return;
      }
  
      // Check if name and message are empty, and set default values if so
      const donationName = donorName.trim() === "" ? "Anonymous" : donorName;
      const donationMessage = donorMessage.trim() === "" ? "I wish all good" : donorMessage;
  
      try {
          const donationAmountInWei = ethers.utils.parseEther(donationAmount);
          const currentAllowance = await tokenContract.allowance(window.ethereum.selectedAddress, contractAddress);
          
          if (currentAllowance.lt(donationAmountInWei)) {
              await tokenContract.approve(contractAddress, donationAmountInWei);
          }
          
          await contract.donate(donationName, donationMessage, donationAmountInWei);
          console.log("Donation successful");
  
          // Fetch updated donations after making a donation
          const updatedDonationsCount = await contract.getDonationsCount();
          const updatedDonations = [];
          let totalAmount = ethers.BigNumber.from(0); // Initialize total amount as BigNumber
          for (let i = 0; i < updatedDonationsCount; i++) {
              const donation = await contract.donations(i);
              updatedDonations.push(donation);
              totalAmount = totalAmount.add(donation.amount); // Use BigNumber arithmetic
          }
          setDonations(updatedDonations.reverse()); // Reverse the array to show latest donations first
          setTotalDonationsAmount(totalAmount.toString()); // Convert BigNumber to string for display
      } catch (error) {
          console.error("Error making donation:", error);
      }
  };

    const disconnectWallet = () => {
        setConnected(false);
    };

    return (
  <div className="walletApp">
<div className='menuApp'>
    {connected && (
        <button onClick={() => console.log("Custom button")} className='green_btt' >
            CAMPAIGN
        </button>
    )}
    {connected ? (
        <button type="button" onClick={disconnectWallet} className='red_btt' >
            DISCONNECT
        </button>
    ) : (
        <div className='connect_MetaMask'>
            <button onClick={connectWallet} className="connect_btn">
                CONNECT
            </button>
        </div>
    )}
</div>
    
    <div className='title'>
      <h1>ShareGoodness</h1>
      <h2>Your decentralized charity platform</h2>
    </div>
    <div className='space'></div>

    <div className="campaign_image_container">
      <img src="/image.png" alt="Description" />
    </div>

    <div className='donated_amount'>
        <h2>Total Donated: {ethers.utils.formatUnits(totalDonationsAmount, 18)} SG</h2>
    </div>
    
    <form onSubmit={(e) => e.preventDefault()} className='campaing_donate_container'>
      <div className="form-group">
        <label htmlFor="donationAmount">Donation Amount (SG)</label>
        <input
          type="number"
          step="1"
          id="donationAmount"
          value={donationAmount}
          onChange={(e) => setDonationAmount(e.target.value)}
          placeholder="0"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="donorName">Name</label>
        <input
          type="text"
          id="donorName"
          value={donorName}
          onChange={(e) => setDonorName(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="donorMessage">Message</label>
        <textarea
          id="donorMessage"
          rows={5}
          value={donorMessage}
          onChange={(e) => setDonorMessage(e.target.value)}
          className="form-control"
        />
      </div>
      <button type="submit"  onClick={makeDonation} className='donate_btt'>
        DONATE
      </button>
    </form>

    <div className="donationCampaign">
      <h1>Support Orphaned Children in Refugee Camps</h1>
      <div className="campaignDescription">
        <p>Every child deserves love, safety, and a bright future. In the challenging conditions of refugee camps, countless children have tragically lost their parents.Our campaign aims to provide essential support and opportunities to orphaned children living in refugee camps. With your help, we will offer them a chance at a brighter future by addressing their immediate needs and providing long-term support.</p>
      </div>
      <h2>Sponsor an orphan today</h2>
      <div className="campaignDescription">
        <p>The plight of orphans and unaccompanied children is severe. Refugee camps are full of children without anyone to look after them – they are the most vulnerable and face the most danger.</p>
        <p>In the refugee camps of East Sudan, the number of orphans has gone up very sharply year-on-year, according to official UN figures.</p>
        <p>Our focus is on refugee camps located in the Horn of Africa, where the plight of orphaned children is particularly dire. These camps, often overcrowded and lacking resources, are home to vulnerable children who have lost their parents to conflict, disease, or other tragedies.</p>
        <p>Every child deserves love, safety, and a chance to thrive. In the face of adversity, these orphaned children are left without the care and support they desperately need. By coming together, we can make a tangible difference in their lives, offering them security, happiness, and opportunities for a better tomorrow.</p>
      </div>
      <h2>Your support is crucial to our mission.</h2>
      <div className="campaignDescription">
        <p>Your sponsorship, whether in the form of Zakat or Sadaqah, provides:</p>
        <p>All the child’s basic needs, including accommodation in a safe space, daily meals, clothes, and medical care.</p>
        <p>A good education until they complete secondary school.</p>
        <p>Rehabilitation, support, and specialist equipment. This is really important, as children are affected by many issues resulting from their harsh environment. Specialist support allows them to grow and develop, realizing their potential.</p>
        <p>Security and reassurance about the future – allowing them to stop worrying about food, water, and finding sources of income.</p>
        <p>Education – an important pathway to better opportunities and a way out of poverty for orphans and those around them.</p>
        <p>Reduced stress: Orphans have so many worries that children simply shouldn’t have at that age. Your support makes a huge difference to them both physically and mentally.</p>
        <p>Regular updates to you: As always, we’ll keep you informed about how the child you support is getting on.</p>
        <p>By creating a fundraising page and rallying your friends and family to contribute, you can help us provide essential resources and services to orphaned children in refugee camps. Even a small donation can have a significant impact, with just £300 annually making a world of difference in a child's life.</p>
        <p>Join us in making a difference today. Create your fundraising page and spread the word to your network, urging them to contribute whatever they can. Together, we can provide orphaned refugees with the love, safety, and bright futures they deserve. Let's give these children hope and opportunity in the face of adversity.</p>
      </div>
    </div>
            

    <div className="donations-history">
      <h2>Donations History</h2>
      {donations.map((donation, index) => (
        <div key={index} className="donation-item">
          <div>Name: {donation.name}</div>
          <div>Message: {donation.message}</div>
          <div>Amount: {ethers.utils.formatUnits(donation.amount, 18)} SG</div>
        </div>
      ))}
    </div>
    <div className='space'></div>
    <div className='getmoretokens'>
      <h1>
        Need to ShareGoodness ?
      </h1>
      <a href='https://quickswap.exchange/#/'>Get more on QuickSwap</a>
    </div>
    <div className='space'></div>
              
  </div>
        
);
}

export default App;
